.test-area {
  height: 100vh;
  background-color: $dark-color;
}
.test-flex {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.dropdown-element {
  position: relative;
  $dropdown_width: 250px;
  $hover_color: $gold-color;
  width: $dropdown_width;
  margin: auto;
  button {
    width: $dropdown_width;
    background-color: $light-color;
    border: 1px solid $border-color;
    color: #333;
    font-size: 1rem;
    font-weight: unset;
    text-transform: unset;
    text-align: center;
    cursor: pointer;
    transition: $transition;
    &:hover {
      color: $hover_color;
    }
    i {
      margin-left: 0.4rem;
      pointer-events: none;
    }
    .choice {
      pointer-events: none;
    }
    &.error {
      border: solid 1px $red-color;
    }
  }
  ul {
    max-height: 200px;
    display: unset;
    margin-top: 30px;
    overflow-y: scroll;
    width: $dropdown_width;
    background-color: $light-color;
    position: absolute;
    background: #fff;
    border: 1px solid $border-color;
    box-shadow: unset;
    left: 0px;
    list-style: none;
    opacity: 0;
    pointer-events: none;
    z-index: 100;
    transform: translateY(-10px);
    transition: all 0.4s ease;
  }
  li {
    width: 100%;
    text-align: center;
    font-size: 1rem;
    padding: 0.4rem 0rem;
    border-bottom: 1px solid $border-color;
    &:hover {
      background-color: lighten($hover_color, 30%);
    }
  }
  &.active {
    ul {
      cursor: pointer;
      opacity: 1;
      pointer-events: all;
      transform: translateY(10px);
    }
  }
}
