.v-header {
  height: 100vh;
  display: flex;
  align-items: center;
  color: #fff;
  .fullscreen-video-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  video {
    min-height: 100%;
    min-width: 100%;
  }

  .z-content {
    position: relative;
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // heigt of navbar
    transform: translateY(-80px);
    width: 100%;
  }

  .header-content-container {
    padding: 3.8rem;
    -webkit-transform: skew(20deg);
    -moz-transform: skew(20deg);
    -o-transform: skew(20deg);
    box-shadow: 4px 4px 4px lighten($dark-color, 2%);
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($light-color, 0.7);
  }
  .header-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    opacity: 1;
    z-index: 10;
  }
  h1 {
    color: $dark-color;
    font-family: $font-script;
    font-size: 4rem;
  }
  span {
    display: block;
    color: $dark-color;
    font-family: $font-script;
    font-size: 2rem;
    transform: translateX(50%);
  }
  @media (max-width: 991.98px) {
    h1 {
      font-size: 3.7rem;
    }
  }
  @media (max-width: 767.98px) {
    .header-content-container {
      padding: 3rem;
    }
    h1 {
      text-align: center;
    }
    span {
      transform: translateX(25%);
    }
  }
  @media (max-width: 575.98px) {
    .header-content-container {
      padding: 2rem;
    }
    h1 {
      // text-align: center;
    }
    span {
      transform: translateX(0);
    }
  }
  @media (max-width: 420.98px) {
    .header-content-container {
      padding: 1rem;
    }
    h1 {
      // text-align: center;
      font-size: 2.8rem;
    }
    span {
      transform: translateX(0);
      font-size: 1.75rem;
    }
  }
}
