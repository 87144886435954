.gallerie-admin {
  padding-bottom: 600px;
  .img_container {
    text-align: center;
    .delete-btn {
      padding: 0.2rem;
      width: 100%;
      text-align: center;
      background-color: $red-color;
      opacity: 0.9;
      color: #fff;
      font-size: 1.2rem;
      margin: 0.2rem 0;
      transition: $transition;
      cursor: pointer;
      &:hover {
        background-color: lighten($red-color, 10%);
      }
    }

    img {
      width: 300px;
      height: 300px;
      object-fit: cover;
      &:hover {
        transform: scale(1);
      }
    }
  }
  .extra-admin {
    padding-top: 0.5rem;
  }
  .title-admin {
    color: #fff;
    font-size: 1.1rem;
    padding-bottom: 0.5rem;
    cursor: pointer;
    transition: $transition;
    :hover {
      color: $gold-color;
    }
  }
  .newGallerieImageColumn {
    input[type='file'] {
      display: none;
    }
  }

  .change-img-label {
    padding: 0.2rem;
    display: block;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 1.2rem;
    background-color: $green-color;
    transition: $transition;
    cursor: pointer;
    &:hover {
      background-color: lighten($green-color, 10%);
    }
  }
}
