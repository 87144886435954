.dynamic-input-container {
  padding: 2rem 0rem;
  .desc {
    font-size: 1.6rem;
    text-align: center;
    font-weight: 600;
  }
  .hr-dynamic-input {
    width: 95%;
    margin: 2rem auto;
  }
  .content-one {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .termin {
    font-family: $font-script;
    color: $gold-color;
    font-weight: 600;
    min-width: 80px;
    display: block;
    padding-right: 1rem;
  }
  .event {
    display: block;
    width: 100%;

    align-self: flex-start;
  }

  .one-ausstellung-example {
    padding: 2rem 0rem;
    max-width: 800px;
    margin: auto;
  }
}
.dynamic-input {
  margin-top: 2rem;
  &.input-small {
    input {
      max-width: 300px;
    }
  }
  .content {
    background-color: #fff;
    display: flex;
    max-width: 800px;
    flex-direction: column;
    justify-content: center;
    border: 1px solid $border-color;
    border-radius: 6px;
  }
  h2 {
    padding-top: 0.8rem;
  }
  .input-container {
    padding: 0.5rem 0rem;
  }
  input,
  textarea {
    width: 100%;
    padding: 0.6rem 0.4rem;
    &::placeholder {
      color: #333;
    }
  }
  textarea {
    height: 80px;
  }
  button {
    margin: 1rem 0rem;
    text-transform: uppercase;
    cursor: pointer;
    &.addBtn {
      background-color: $green-color;
    }
    &.deleteBtn {
      display: none;
      background-color: $red-color;
    }
    &.updateBtn {
      display: none;
      background-color: $orange-color;
    }
  }
}
.items {
  margin: 2rem 0rem;
  padding: 2rem 0rem;
  font-size: 1.2rem;
  max-width: 800px;
  margin: auto;
}

.one_item {
  margin-bottom: 2rem;
  .titel {
    display: flex;
  }
  h3 {
    color: $green-color;
    font-weight: 600;
  }
  i {
    padding-left: 1rem;
    cursor: pointer;
    transition: all 0.2s ease-in;
    &:hover {
      color: $green-color;
    }
  }
  .content-one {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .termin {
    font-family: $font-script;
    color: $gold-color;
    font-weight: 600;
    min-width: 80px;
    display: block;
    padding-right: 1rem;
  }
  .event {
    display: block;
    width: 100%;
    align-self: flex-start;
  }
}
