.kontakt {
  background-color: #fff;
  max-width: 600px;
  margin: 3rem auto 2rem auto;
  -webkit-box-shadow: 3px 3px 5px 6px #ccc;
  -moz-box-shadow: 3px 3px 5px 6px #ccc;
  box-shadow: 3px 3px 5px 6px #ccc;
  border-radius: 12px;
  @media (max-width: 767.98px) {
    margin: 2rem 5%;
    .desc {
      font-size: 1rem;
    }
  }

  .content-container {
    margin: auto;
    margin-bottom: 1rem;
    max-width: 600px;
  }
  .title-script {
    color: $gold-color;
    font-family: $font-script;
    font-size: 3rem;
    text-align: center;
    padding-bottom: 1rem;
  }
  .desc {
    margin-bottom: 0rem;
    font-size: 1.2rem;
    color: $dark-color;
  }
  .law-container {
    display: flex;
    padding: 1rem 0rem;
    font-size: 0.8rem;
    a {
      font-size: 0.8rem;
      color: $gold-color;
      transition: $transition;
      &:hover {
        color: darken($gold-color, 10%);
      }
    }
  }

  .kontaktdaten {
    input {
      padding-left: 0.5rem;
    }
  }

  .frage-input-2 {
    display: flex;
    input {
      width: 100%;
      margin-bottom: 0.8rem;
      &::placeholder {
        color: #333;
      }
    }
  }
  .frage-input-3 {
    margin-bottom: 0.8rem;
    input {
      width: 100%;
      &::placeholder {
        color: #333;
      }
    }
  }
  .frage-input-4 {
    textarea {
      padding-left: 0.5rem;
      width: 100%;
      height: 200px;
      &::placeholder {
        color: #333;
      }
    }
  }
  .btn-container {
    padding-top: 16px;
    text-align: center;
    button{
      text-transform: none  !important;
    }

  }
  small {
    color: $red-color;
    font-weight: 600;
    font-size: 1rem;
    opacity: 0;
    transition: all 0.25 ease-out;
    &.error {
      opacity: 1;
    }
  }
  input,
  textarea {
    transition: all 0.25 ease-out;
  }
  input.error,
  textarea.error {
    border: solid 1px $red-color;
  }
}
