.law {
  transform: translateY(-80px);
  padding-bottom: 8rem;
  padding-top: 4rem;
  background-image: linear-gradient(
      135deg,
      transparent 0%,
      transparent 6%,
      rgba(71, 71, 71, 0.04) 6%,
      rgba(71, 71, 71, 0.04) 22%,
      transparent 22%,
      transparent 100%
    ),
    linear-gradient(
      45deg,
      transparent 0%,
      transparent 20%,
      rgba(71, 71, 71, 0.04) 20%,
      rgba(71, 71, 71, 0.04) 47%,
      transparent 47%,
      transparent 100%
    ),
    linear-gradient(
      135deg,
      transparent 0%,
      transparent 24%,
      rgba(71, 71, 71, 0.04) 24%,
      rgba(71, 71, 71, 0.04) 62%,
      transparent 62%,
      transparent 100%
    ),
    linear-gradient(
      45deg,
      transparent 0%,
      transparent 73%,
      rgba(71, 71, 71, 0.04) 73%,
      rgba(71, 71, 71, 0.04) 75%,
      transparent 75%,
      transparent 100%
    ),
    linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
  .top-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 575.98px) {
      flex-direction: column;
    }
  }
  h1 {
    // text-align: center;
    color: $gold-color;
    font-size: 3rem;
    font-weight: 600;
    @media (max-width: 575.98px) {
      font-size: 1.5rem;
    }
  }

  h2 {
    padding-top: 1rem;
  }

  p {
    margin: 0.2rem 0rem;
    padding: 0rem 0rem;
  }
  h3 {
    padding-top: 0.6rem;
    color: $gold-color;
  }
  a {
    text-decoration: underline;
  }
  hr {
    width: 60px;
    color: $gold-color;
    height: 2px;
    background-color: $gold-color;
    margin-right: auto;
    border: none;
    margin-top: 0.2rem;
    margin-bottom: 1rem;
  }

  li,
  dd {
    padding-bottom: 0.2rem;
  }

  .right-col {
    padding-bottom: 2rem;
    .content {
      background-color: #fff;
      border-radius: 4px;
      padding: 1rem 12px;

      box-shadow: $main-box-shadow;
      ul {
        font-size: 0.9rem;
        color: $gray-color;
        .z-bold {
          color: #000000;
        }
      }
    }
  }
  .left-col {
    .content {
      border-radius: 4px;
      padding: 1rem 12px;
    }
  }
}
