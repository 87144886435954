@import 'allfiles';
@import '../app/main.scss';
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: $font-family;
  font-size: 1rem;
  line-height: 1.6;
  background-color: #fff;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}
a {
  text-decoration: none;
  color: #333;
  font-size: 1rem;
  &:hover {
    text-decoration: none;
    color: $third-color;
  }
}
.title {
  font-size: 2rem;
  font-weight: 600;
}

ul {
  list-style: none;
}
// sticky footer
html {
  height: 100%;
}
body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

footer {
  margin-top: auto;
}

.alle-Konten {
  min-height: 200px;
  border: solid 4px $primary-color;
  margin: 2rem 0rem;
  .title {
    font-size: 2rem;
    color: $third-color;
    font-weight: 600;
    padding: 1rem 0rem;
  }
  li {
    padding-bottom: 1rem;
    padding-left: 1rem;
    font-size: 1.4rem;
  }
}

.mobile-overflow-wrapper {
  overflow-x: hidden;
}
.dark-background {
  background-color: $dark-color;
}
@import 'media';
