.gallery {
  background-color: $dark-color;
  padding: 2rem 0rem;
  min-height: 600px;
  .title {
    text-align: center;
    font-family: $font-script;
    font-size: 3rem;

    color: #fff;
  }
  hr {
    height: 4px;
    width: 50px;
    color: $gold-color;
    background-color: $gold-color;
    border: none;
    margin: auto;
    margin-bottom: 3rem;
    text-align: center;
  }
  .img_container {
    padding-bottom: 1rem;

    img {
      transition: all 1s ease-out;
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .link-container {
    text-align: center;
    padding: 2rem 0rem;
    a {
      font-size: 1.2rem;
    }
  }
  @media (max-width: 575.98px) {
    .title {
      font-size: 2rem;
    }
  }
}
.gallery-unterseite {
  transform: translateY(-80px);
  // background-color: $dark-color;
  background-color: #fff;
  padding: 2rem 0rem;
  min-height: 300px;
  .title {
    text-align: center;
    font-family: $font-script;
    font-size: 3rem;
    color: $gold-color;
  }
  hr {
    height: 4px;
    width: 50px;
    color: $gold-color;
    background-color: $gold-color;
    border: none;
    margin: auto;
    margin-bottom: 3rem;
    text-align: center;
  }
  .img_container {
    padding-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      transition: all 1s ease-out;
      cursor: pointer;
      width: 300px;
      height: 300px;
      object-fit: cover;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  @media (max-width: 575.98px) {
    .title {
      font-size: 2rem;
    }
  }
}
