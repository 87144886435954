.ausstellungen_home {
  .title-script {
    color: $gold-color;
    font-family: $font-script;
    font-size: 3rem;
    text-align: center;
    padding-bottom: 1rem;
  }
  .content_container {
    background-color: lighten($light-color, 0%);
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 2rem;
    flex-direction: column;
  }
  p {
    font-size: 1.2rem;
  }
  .a_container {
    padding: 4rem 0rem;
  }
  .img_container {
    margin: 0;
    padding: 0;
    height: 500px;
    img {
      margin: 0;
      padding: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 991.98px) {
    .img_container {
      display: none;
    }
  }
}
