/* von Netflix Traversy-Media  und sass youtube kurse*/
// $primary-color: #42484e;
$primary-color: #dadada;
$secondary-color: #dadada;
$third-color: #3b86ff;
$gold-color: #e15609;
$fourth-color: #2f6bcc;
$header-color-2: #fffce9;
$footer-color: #445b80;
$img-background: #1b3333;

$border-color: lighten(#333, 60%);

$test-color: #e15609;

$dark-color: #0b1826;
$gray-color: #999;
$light-color: #f4f4f4;
$red-color: #dc3545;
$green-color: #28a745;
$orange-color: #ffa500;

$max-width: 968px;

$primary-overlay: lighten($primary-color, 20%);

$transition: all 0.4s ease-out;

@font-face {
  font-family: 'Shadows Into Light';
  src: url('/src/assets/fonts/Shadows_Into_Light/ShadowsIntoLight-Regular.ttf') format('truetype');
}

$font-family: Arial, Helvetica, sans-serif;
$font-script: 'Shadows Into Light', cursive;
$main-box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
