.waves-container {
  min-height: 300px;
  position: relative;
  border-bottom: solid $dark-color 0.2px;
  .waves {
    display: block;
    width: 100%;
    bottom: 0;
    height: 100%;
    position: absolute;
    z-index: -10;
    svg {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      animation-name: move-left;
      animation-duration: 4s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
  }
}
