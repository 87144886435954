.presse {
  background-color: $dark-color;
  padding: 2rem 0rem;
  transform: translateY(-80px);
  .img_container {
    padding-bottom: 1rem;
    text-align: center;
    img {
      height: 400px;
      width: auto;
      max-width: 300px;
      object-fit: cover;
      transition: all 1s ease-out;
      cursor: pointer;
    }
  }
}
