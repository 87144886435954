button {
  padding: 0.6rem 1.2rem;
  background-color: $gold-color;
  text-transform: uppercase;
  color: #fff;
  transition: all 0.3s ease-in;
  border: none;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background-color: darken($gold-color, 10%);
  }
  &:focus {
    outline: none;
  }
  &:active {
    transform: scale(0.98);
  }
}

button.transparent {
  color: #333;
  background-color: #fff;
  &:hover {
    color: $third-color;
  }
}

a.btn {
  padding: 0.8rem 1.2rem;
  background-color: $gold-color;
  text-transform: uppercase;
  color: #fff;
  transition: all 0.25s ease-in;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background-color: darken($gold-color, 10%);
    color: #fff;
  }
}
