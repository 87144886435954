.backend-navbar {
  background-color: $gold-color;
  border-bottom: solid 1px #333;
  nav {
    display: block;
    text-align: center;
    width: 100%;
    top: 0;
    opacity: 0.8;
    padding: 0.5rem;

    h1 a {
      color: #fff;
      font-size: 3rem;
      font-weight: 600;
      cursor: pointer;
      text-transform: uppercase;
      a:hover {
        color: $red-color;
      }
    }
    ul {
      display: flex;
      text-align: center;
      justify-content: center;
      li {
        margin-top: 0rem;
      }
      a {
        color: #fff;
        text-transform: uppercase;
        padding: 0rem 0.45rem;
        margin: 0 0.25rem;
        font-size: 1.2rem;
        &:hover {
          color: #333;
        }
      }
    }
  }
  @media (min-width: 700px) {
    nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      ul {
        li {
          margin-top: 1.4rem !important;
        }
      }
      h1 {
        margin-top: 1.1rem;
      }
    }
  }
}
