.header_unter {
  position: relative;
  background: url('../img-background/header_unter.jpg') no-repeat center
    center/cover;
  height: 250px;
  width: 100%;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-80px);
  .content {
    padding: 2rem 5%;
  }
  .title {
    text-transform: uppercase;
    transform: translateY(30px);
    color: #fff;
    font-size: 4rem;
    font-family: $font-script;
    text-align: center;
    padding-bottom: 1rem;
  }
  @media (max-width: 575.98px) {
    .title {
      text-transform: uppercase;
      transform: translateY(30px);
      color: #fff;
      font-size: 2.5rem;
      font-family: $font-script;
      text-align: center;
      padding-bottom: 1rem;
    }
  }
}

.hintergrund-bild {
  position: relative;
  background: url('../img-background/interesse_bild.jpg') no-repeat center
    center/cover;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    background-color: #fff;
    padding: 2rem 5%;
    max-width: 320px;
    margin: auto;
    border-radius: 4px;
    border: 1px solid $gold-color;
  }
  .title {
    text-transform: uppercase;
    color: $gold-color;
    font-size: 2.2rem;
    text-align: center;
    padding-bottom: 1rem;
  }
  p {
    font-size: 1.2rem;
    text-align: center;
    padding-bottom: 1.5rem;
  }
  .link-container {
    text-align: center;
  }
}
