.button_3d_section {
  $text: #fff;
  $light-pink: lighten($gold-color, 5%);
  $pink: $gold-color;
  $dark-pink: darken($gold-color, 10%);
  $pink-border: darken($gold-color, 16%);
  $pink-shadow: lighten($gold-color, 40%);
  padding-bottom: 3rem;
  .title-script {
    color: $dark-color;
    font-weight: 600;
    font-size: 2rem;
    font-family: $font-script;
    text-align: center;
    padding-bottom: 1rem;
  }
  .z-container {
    height: 80px;
    width: 100%;
    max-width: 400px;

    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: auto;
  }
  button.button_3d {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    font-size: inherit;
    font-family: inherit;

    font-weight: 600;
    color: $text;
    text-transform: uppercase;
    padding: 1.25em 2em;
    background: $light-pink;
    border: 2px solid $pink-border;
    border-radius: 0.75em;
    transform-style: preserve-3d;
    transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
      background 150ms cubic-bezier(0, 0, 0.58, 1);
    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $dark-pink;
      border-radius: inherit;
      box-shadow: 0 0 0 2px $pink-border, 0 0.625em 0 0 $pink-shadow;
      transform: translate3d(0, 0.75em, -1em);
      transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
        box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
    }
    &:hover {
      background: $pink;
      transform: translate(0, 0.25em);
      &::before {
        box-shadow: 0 0 0 2px $pink-border, 0 0.5em 0 0 $pink-shadow;
        transform: translate3d(0, 0.5em, -1em);
      }
    }
    &:active {
      background: $pink;
      transform: translate(0em, 0.75em);
      &::before {
        box-shadow: 0 0 0 2px $pink-border, 0 0 $pink-shadow;
        transform: translate3d(0, 0, -1em);
      }
    }
  }
  @media (max-width: 575.98px) {
    .title-script {
      font-size: 2.4rem;
    }
  }
}
