.ausstellung_unter {
  .title {
    text-align: center;
    font-family: $font-script;
    font-size: 3rem;
    color: $dark-color;
    transform: translateY(-40px);
  }
  hr {
    height: 4px;
    width: 50px;
    color: $gold-color;
    background-color: $gold-color;
    border: none;
    margin: auto;
    text-align: center;
  }
  .desc {
    font-size: 1.2rem;
    color: $dark-color;
    max-width: 800px;
    margin: auto;
    padding-bottom: 2rem;
  }
  .content-one {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .ausstellungen {
    padding: 2rem 0rem;
    font-size: 1.2rem;
    max-width: 800px;
    margin: auto;
  }
  .termin {
    font-family: $font-script;
    color: $gold-color;
    font-weight: 600;
    min-width: 80px;
    display: block;
    padding-right: 1rem;
  }
  .event {
    display: block;
    width: 100%;
    align-self: flex-start;
  }

  .one-ausstellung {
    padding-bottom: 1rem;
  }
}
