.user-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 6rem;
  .title {
    margin-bottom: 2rem;
    font-size: 2rem;
    font-weight: 600;
    color: $gold-color;
  }
  .helper-links {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      display: block;
      color: $third-color;
      margin-left: 0.8rem;
      transition: all 0.2s ease-in;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.user-form {
  background-color: #fff;
  -webkit-box-shadow: 3px 3px 5px 6px #ccc;
  -moz-box-shadow: 3px 3px 5px 6px #ccc;
  box-shadow: 3px 3px 5px 6px #ccc;
  padding: 2rem 0.5rem;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 550px;
  .input-container {
    width: 100%;
    margin-bottom: 2rem;
  }
  input {
    padding: 0.8rem 0.2rem;
    font-size: 1rem;
    font-weight: 600;
    width: 100%;
    background-color: $light-color;
    border: none;
    border-bottom: 2px solid $gray-color;
    &::placeholder {
      color: $gray-color;
    }
    &:focus {
      border-bottom: 2px solid $gold-color;
    }
  }
  button {
    background-color: $gold-color;
    padding: 0.6rem 1.2rem;
    color: #fff;
    font-size: 1.2rem;
    transition: all 0.2s ease-in;
    border-radius: 12px;
    cursor: pointer;
    &:hover {
      background-color: lighten($gold-color, 10%);
    }
    &.btn-off {
      background-color: lighten($gold-color, 30%);
      cursor: not-allowed;
      border-style: outset;
    }
  }
  @media (max-width: 575.98px) {
    .user-form {
      width: 95%;
      margin: 0 auto;
    }
  }
}
