.welcome-text {
  padding: 0rem 0rem 2rem 0rem;
  img {
    cursor: pointer;
  }
  .content-img {
    text-align: center;
    display: none;
    img {
      height: 250px;
      width: auto;
      border-radius: 12px;
    }
    @media (max-width: 767.98px) {
      display: block;
    }
  }
  .title-script {
    color: $gold-color;
    font-family: $font-script;
    font-size: 3rem;
    text-align: center;
    padding-bottom: 1rem;
    @media (max-width: 575.98px) {
      font-size: 2rem;
    }
  }
  .z-br {
    margin: 1.6rem;
  }
  p {
    font-size: 1.2rem;
  }
  .motto {
    display: block;
    font-size: 2rem;
    text-align: center;
    padding: 1.5rem 0rem;
    color: $gold-color;
    font-family: $font-script;
  }
  .ende-text {
    color: $gold-color;
    font-family: $font-script;
    font-size: 2rem;
    padding-top: 2rem;
    text-align: center;
  }
  .img_container {
    position: relative;
    margin-top: 3rem;
    width: 100%;
    text-align: center;
    img {
      height: 500px;
      border-radius: 12px;
      width: auto;
    }
    @media (max-width: 767.98px) {
      display: none;
    }
  }
}

.description-home {
  .step-number {
    color: $gray-color;
    text-align: center;
    padding-bottom: 1rem;
    font-size: 1.2rem;
  }
  .content {
    background-color: $primary-color;
    color: $dark-color;
    padding: 2rem 2%;
    min-height: 500px;
  }
  .content-1 {
    background-color: #fff;
  }
  .img-container {
    margin: 0;
    padding: 0;

    width: 100%;
    img {
      height: 100%;
      width: 100%;
      min-height: inherit;
      object-fit: cover;
    }
  }
  .title {
    text-align: center;
    padding-bottom: 1.5rem;
  }
  p {
    font-size: 1.2rem;
  }
}
